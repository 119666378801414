import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Button, Box, Text } from "../../components/Core";

const CTA = () => (
  <>
    {/* <!-- CTA section --> */}
    <Box bg="dark" className="position-relative" py={[5, null, null, 5, 6]}>
      <Container>
        <Row className="justify-content-center">
          <Col md="8" lg="7">
            <Box className="text-center">
              <Title color="light">Start your journey to full recovery</Title>
              {/* <Text mb={4} color="light" opacity={0.7}>
                Create custom landing pages with Omega that converts more
                visitors than any website.
              </Text> */}
              <div className="d-flex flex-column align-items-center pt-3">
              <a href="https://api.whatsapp.com/send/?phone=917611078653&text=I%27m+interested+in+a+treatment+plan">
                <Button mb={2}>Free treatment plan</Button>
              </a>
                {/* <Text
                  variant="small"
                  fontSize={"14px"}
                  color="light"
                  opacity={0.7}
                >
                  No credit card required
                </Text> */}
              </div>
            </Box>
          </Col>
        </Row>
      </Container>
    </Box>
  </>
);

export default CTA;
