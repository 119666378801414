import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";
import { device } from "../../utils";

import iconOne from "../../assets/image/png/icons/1.png";
import iconTwo from "../../assets/image/png/icons/2.png";
import iconThree from "../../assets/image/png/icons/3.png";

// border-bottom: ${({ theme }) => `1px solid ${theme.colors.border}`};
const SectionStyled = styled(Section)`
  padding-top: 47px;
  padding-bottom: 23px;
  
  @media ${device.md} {
    padding-top: 87px;
    padding-bottom: 63px;
  }
`;

const FeatureCard = ({ iconImage, title, children, ...rest }) => (
  <Box {...rest}>
    <Box mb={[3, 3, 3, 4]} pb={2}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const Feature = () => (
  <>
    {/* <!-- Feature section --> */}
    <SectionStyled>
      <Container>
        <Box className="text-center">
            <Title>How it works</Title>
        </Box>
        <Row className="justify-content-center mt-5">
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Visit a local clinic" iconImage={iconOne}>
            Visit our partner clinics in Africa to discuss your conditions and recommended treatments
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Get treatment plan from hospitals" iconImage={iconTwo}>
            We send you treatment plans from hospitals based on your doctor’s recommendations.
            </FeatureCard>
          </Col>
          <Col lg="4" className="mb-5 mb-lg-4">
            <FeatureCard title="Treatment in India" iconImage={iconThree}>
            Undergo a tension-free treatment, while Zahab takes care of your travel, accomodation and everything else.
            </FeatureCard>
          </Col>
        </Row>
      </Container>
    </SectionStyled>
  </>
);

export default Feature;
